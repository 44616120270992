import React from 'react';
import AdminDashboard from './admin-dashboard/AdminDashboard';
import { useSelector } from 'react-redux';
import { routesName } from '../../constants/routes-name';
import { Navigate } from 'react-router-dom';

function Component() {
  const { user, isCompany, isEmployee, isUser, company } = useSelector(
    ({ user }) => user
  );

  if (!isEmployee) {
    return <Page />;
  } else if (isEmployee) {
    return <Navigate to={routesName.profile} replace={true} />;
  }
  return <Navigate to={routesName[404]} replace={true} />;
}

function Page() {
  return (
    <div>
      <AdminDashboard />
    </div>
  );
}

export default Component;

import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { routesName } from '../constants/routes-name';

let loginRedirectUrl = routesName.login;

const Authorization = (props) => {
  const location = useLocation();
  const { pathname, search } = location;
  const {
    userRole,
    children,
    loginRedirectUrl: propLoginRedirectUrl = '/',
  } = props;
  const [accessGranted, setAccessGranted] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (accessGranted) {
      redirectRoute();
    }
  }, []);

  useEffect(() => {
    if (accessGranted) {
      redirectRoute();
    }
  }, [accessGranted, userRole, location]);

  const isAuthPath = (pathname) => {
    if (
      pathname === routesName.login ||
      pathname === routesName.signup ||
      pathname === routesName.resetPassword ||
      `${pathname}`.includes('/resetPassword/') ||
      `${pathname}`.includes('/verifyAccount/') ||
      `${pathname}`.includes(routesName.viewCompanyPath)  ||
      `${pathname}`.includes(routesName.viewCardPath) 
    ) {
      return true;
    }
    return false;
  };

  const redirectRoute = () => {
    // User is guest
    // Redirect to Login Page or UnAuthorized pages
    if (!userRole || userRole.length === 0) {
      if (isAuthPath(pathname) || pathname === routesName.faqs ) {
         navigate(`${pathname}${search}`);
      } else navigate(routesName.home);
      loginRedirectUrl = pathname;
      setAccessGranted(false);
    } else {
      // User is member
      // User must be on Authorized page or just logged in
      // Redirect to dashboard or loginRedirectUrl

      if (isAuthPath(pathname) &&  !`${pathname}`.includes(routesName.viewCompanyPath) ) {
        navigate(routesName.dashboard);
      } else navigate(`${pathname}${search}`);

      loginRedirectUrl = propLoginRedirectUrl;
      setAccessGranted(false);
    }
  };

  return !accessGranted ? <>{children}</> : <></>;
};

export default Authorization;

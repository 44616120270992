import React, { useEffect, useState } from 'react';
import { AppLogo } from '../../assets';
import { Link, useLocation } from 'react-router-dom';
import {
  ChevronLeft,
  ChevronRight,
  EventNote,
  ExpandLess,
  ExpandMore,
  Group,
  HomeOutlined,
  Leaderboard,
  Person,
  PhoneIphone,
  Settings,
} from '@mui/icons-material';
import { Collapse, List } from '@mui/material';
import { routesName } from '../../constants/routes-name';
import { useSelector } from 'react-redux';

function SlideMenu({ isMenuOpen = false, setIsMenuOpen = () => {} }) {
  const { user, isCompany, isEmployee, isUser, company } = useSelector(
    ({ user }) => user
  );
  const forCards = 'opens-sub-menu-for-cards';
  const forSettings = 'opens-sub-menu-for-settings';
  const location = useLocation();
  const [isSubMenuOpen, setIsSubMenuOpen] = useState('');

  const openSubMenueFor = () => {
    if (
      location.pathname === routesName.cardsListing ||
      location.pathname === routesName.templates
    ) {
      setIsSubMenuOpen(forCards);
    }
  };

  useEffect(() => {
    openSubMenueFor();
  }, []);

  const handleSubMenuToggleClick = (openFor = '') => {
    setIsSubMenuOpen(isSubMenuOpen === openFor ? '' : openFor);
  };

  const handleToggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div
      style={{ userSelect: 'none', zIndex: 9999 }}
      className="relative h-screen"
    >
      <Collapse orientation="horizontal" in={isMenuOpen} collapsedSize={0}>
        <div className="h-screen bg-main w-56 py-8 overflow-y-auto">
          <div className="flex justify-center items-center mt-4">
            <img alt="logo" src={AppLogo} height={50} width={50} />
          </div>

          <div className="mt-8 flex flex-col overflow-y-auto gap-2">
            <Link
              onClick={handleSubMenuToggleClick}
              className={`menu-item px-8 py-6 flex items-center gap-2 ${
                location.pathname === routesName.dashboard ? 'menu-active' : ''
              }`}
              to={routesName.dashboard}
            >
              <HomeOutlined /> <span>Dashboard</span>
            </Link>
            {user?.role_id === 1 && (
              <Link
                onClick={handleSubMenuToggleClick}
                className={`menu-item px-8 py-6 flex items-center gap-2 ${
                  location.pathname === routesName.usersListing
                    ? 'menu-active'
                    : ''
                }`}
                to={routesName.usersListing}
              >
                <Group /> <span>Users</span>
              </Link>
            )}
            {user?.role_id === 1 && (
              <Link
                onClick={handleSubMenuToggleClick}
                className={`menu-item px-8 py-6 flex items-center gap-2 ${
                  location.pathname === routesName.companyListing
                    ? 'menu-active'
                    : ''
                }`}
                to={routesName.companyListing}
              >
                <Leaderboard /> <span>Compnies</span>
              </Link>
            )}
            {(user?.role_id === 1 || isUser || isCompany) && (
              <Link
                onClick={handleSubMenuToggleClick}
                className={`menu-item px-8 py-6 flex items-center gap-2 ${
                  location.pathname === routesName.employessListing
                    ? 'menu-active'
                    : ''
                }`}
                to={routesName.employessListing}
              >
                <Person />
                <span>Employess</span>
              </Link>
            )}
            <List sx={{ p: 0 }} component="nav">
              <div
                className="menu-item px-8 py-6 flex items-center gap-2 cursor-pointer"
                onClick={() => handleSubMenuToggleClick(forCards)}
              >
                <PhoneIphone />
                <span>Cards</span>
                {isSubMenuOpen === forCards ? <ExpandLess /> : <ExpandMore />}
              </div>
              <Collapse in={isSubMenuOpen === forCards} timeout="auto">
                <div className="pl-6">
                  <Link
                    className={`menu-item px-8 py-6 flex items-center gap-2 ${
                      location.pathname === routesName.templates
                        ? 'menu-active'
                        : ''
                    }`}
                    to={routesName.templates}
                  >
                    <span>Templates</span>
                  </Link>
                  <Link
                    className={`menu-item px-8 py-6 flex items-center gap-2 ${
                      location.pathname === routesName.cardsListing
                        ? 'menu-active'
                        : ''
                    }`}
                    to={routesName.cardsListing}
                  >
                    <span>Cards List</span>
                  </Link>
                </div>
              </Collapse>
            </List>
            {user?.role_id === 1 && (
              <Link
                onClick={handleSubMenuToggleClick}
                className={`menu-item px-8 py-6 flex items-center gap-2 ${
                  location.pathname === routesName.package ? 'menu-active' : ''
                }`}
                to={routesName.package}
              >
                <EventNote /> <span>Packages</span>
              </Link>
            )}
            {(isUser || isCompany) && (
              <Link
                onClick={handleSubMenuToggleClick}
                className={`menu-item px-8 py-6 flex items-center gap-2 ${
                  location.pathname === routesName.billing ? 'menu-active' : ''
                }`}
                to={routesName.billing}
              >
                <EventNote /> <span>Billing</span>
              </Link>
            )}
            {isUser && (
              <Link
                onClick={handleSubMenuToggleClick}
                className={`menu-item px-8 py-6 flex items-center gap-2 ${
                  location.pathname === routesName.pricing ? 'menu-active' : ''
                }`}
                to={routesName.pricing}
              >
                <EventNote /> <span>Pricing</span>
              </Link>
            )}
            {user?.role_id === 1 && (
              <List sx={{ p: 0 }} component="nav">
                <div
                  className="menu-item px-8 py-6 flex items-center gap-2 cursor-pointer"
                  onClick={() => handleSubMenuToggleClick(forSettings)}
                >
                  <Settings />
                  <span>Settings</span>
                  {isSubMenuOpen === forSettings ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )}
                </div>
                <Collapse in={isSubMenuOpen === forSettings} timeout="auto">
                  <div className="pl-6">
                    {/* <Link
                      className={`menu-item px-8 py-6 flex items-center gap-2 ${
                        location.pathname === routesName.appSetting
                          ? 'menu-active'
                          : ''
                      }`}
                      to={routesName.appSetting}
                    >
                      <span>Application</span>
                    </Link> */}
                    <Link
                      className={`menu-item px-8 py-6 flex items-center gap-2 ${
                        location.pathname === routesName.paymentSetting
                          ? 'menu-active'
                          : ''
                      }`}
                      to={routesName.paymentSetting}
                    >
                      <span>Payment</span>
                    </Link>
                    <Link
                      className={`menu-item px-8 py-6 flex items-center gap-2 ${
                        location.pathname === routesName.faqsSetting
                          ? 'menu-active'
                          : ''
                      }`}
                      to={routesName.faqsSetting}
                    >
                      <span>FAQs</span>
                    </Link>
                  </div>
                </Collapse>
              </List>
            )}
          </div>
        </div>
      </Collapse>
      <span
        onClick={handleToggleMenu}
        className={`rounded-full p-1 text-white bg-main absolute top-[48.5%] cursor-pointer left-[92%]`}
      >
        {isMenuOpen ? (
          <ChevronLeft fontSize="large" />
        ) : (
          <ChevronRight fontSize="large" />
        )}
      </span>
    </div>
  );
}

export default SlideMenu;
/*  */

import * as yup from 'yup';

export const validationSchema = {
  nullable: yup.string().nullable(),
  required: (title) =>
    yup.string().trim().nullable().required(`${title} is required.`),
  name: yup
    .string()
    .trim()
    .required('Name is required.')
    .max(45, 'Name must be at most 45 characters.')
    .matches(/^[a-zA-Z ]*$/, 'Invalid Name'),
  minRequired: (title, min) =>
    yup
      .number()
      .required(`${title} is required`)
      .min(1, `${title} should be grater than ${min}`),
  companyName: yup.string().trim().required('Company Name is required.'),
  cardNumber: yup
    .string()
    .trim()
    .required('Card nomber is required.')
    .min(19, 'Card nomber should be 16 cherecters'),
  currentPassword: yup.string().trim().required('Password is required.'),
  newPassword: yup
    .string()
    .trim()
    .required('Password is required.')
    .min(8, 'Password should be at least 8 cherecters')
    .max(20, 'Password should be at most 8 cherecters')
    .notOneOf(
      [yup.ref('currentPassword'), null],
      'Use diffrent with current password'
    )
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,20}$/,
      'Password must be 8-20 characters and consist of 1 digit(0-9), upper & lower case(Aa-Zz), a symbol'
    ),
  confirmNewPassword: yup
    .string()
    .required('Confirm password is required')
    .oneOf(
      [yup.ref('newPassword'), null],
      'New and confirm password must same.'
    ),
  password: yup
    .string()
    .trim()
    .required('Password is required.')
    .min(8, 'Password should be at least 8 cherecters')
    .max(20, 'Password should be at most 8 cherecters')
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,20}$/,
      'Password must be 8-20 characters and consist of 1 digit(0-9), upper & lower case(Aa-Zz), a symbol'
    ),
  confirmPassword: yup
    .string()
    .required('Confirm password is required')
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
  email: yup
    .string()
    .trim()
    .required('Email is required.')
    .email('Invalid email.'),
  image: yup
    .mixed()
    .nullable()
    .test('is-valid-type', 'Not a valid image type', (value) =>
      value ? isValidFileType(value && value.name.toLowerCase(), 'image') : true
    )
    .test('is-valid-size', 'Max allowed size is 2mb', (value) =>
      value ? value.size <= maxImageSizeAlloud : true
    ),
};

function isValidFileType(fileName, fileType) {
  return (
    fileName &&
    validFileExtensions[fileType].indexOf(fileName.split('.').pop()) > -1
  );
}

const validFileExtensions = {
  image: ['png', 'svg'],
};
/* const validFileExtensions = {
  image: ['jpg', 'gif', 'png', 'jpeg', 'svg', 'webp'],
}; */

const maxImageSizeAlloud = 2097152; //2mb
